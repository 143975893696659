/// Insert here the js for intraction / manipulation for payments

function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
        if (typeof object[key] !== 'object') formData.append(key, object[key]);
        else formData.append(key, JSON.stringify(object[key]));
    });
    return formData;
}

